import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import useSound from "use-sound";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";

import sound1 from "./assets/s1.mp3";
import sound2 from "./assets/s2.mp3";
import sound3 from "./assets/s3.mp3";
import sound4 from "./assets/s4.mp3";

import { ReactComponent as OnIcon } from "./assets/on.svg";
import { ReactComponent as OffIcon } from "./assets/off.svg";

const queryClient = new QueryClient();
const maxToShow = 20;
const interval = 15000;

const products = [
  { lable: "Select a Product", title: "" },
  { lable: "Collection", title: "collection" },
  { lable: "Biometric", title: "biometric" },
  { lable: "Return Visa", title: "returnvisa" },
  {
    lable: "Residence endorsement sticker",
    title: "residenceendorsementsticker",
  },
  {
    lable: "Residence endorsement sticker Ukraine",
    title: "residenceendorsementstickerukraine",
  },
  {
    lable: "Family reunification Asylum",
    title: "familyreunificationasylum",
  },
];
const productsData = {
  collection: {
    title:
      "Collecting your residence document, registration card or original document",
    link: "https://oap.ind.nl/oap/en/#/doc",
    branches: [
      {
        title: "IND Amsterdam",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/AM/slots/?productKey=DOC&persons=1",
      },
      {
        title: "IND Den Haag",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DH/slots/?productKey=DOC&persons=1",
      },
      {
        title: "IND Zwolle",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/ZW/slots/?productKey=DOC&persons=1",
      },
      {
        title: "IND Den Bosch",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DB/slots/?productKey=DOC&persons=1",
      },
    ],
  },
  biometric: {
    title: "Biometric information",
    link: "https://oap.ind.nl/oap/en/#/BIO",
    branches: [
      {
        title: "IND Amsterdam",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/AM/slots/?productKey=BIO&persons=1",
      },
      {
        title: "IND Den Haag",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DH/slots/?productKey=BIO&persons=1",
      },
      {
        title: "IND Zwolle",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/ZW/slots/?productKey=BIO&persons=1",
      },
      {
        title: "IND Den Bosch",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DB/slots/?productKey=BIO&persons=1",
      },
      {
        title: "IND Haarlem",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/6b425ff9f87de136a36b813cccf26e23/slots/?productKey=BIO&persons=1",
      },
      {
        title: "Expatcenter Wageningen",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/b084907207cfeea941cd9698821fd894/slots/?productKey=BIO&persons=1",
      },
      {
        title: "Expatcenter Enschede",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/3535aca0fb9a2e8e8015f768fb3fa69d/slots/?productKey=BIO&persons=1",
      },
      {
        title: "Expatcenter Utrecht",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/fa24ccf0acbc76a7793765937eaee440/slots/?productKey=BIO&persons=1",
      },
      {
        title: "Expatcenter Nijmegen",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/0d85a757c13105ba0c26c3d177a7a884/slots/?productKey=BIO&persons=1",
      },
    ],
  },
  returnvisa: {
    title: "Return Visa",
    link: "https://oap.ind.nl/oap/en/#/TKV",
    branches: [
      {
        title: "IND Amsterdam",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/AM/slots/?productKey=TKV&persons=1",
      },
      {
        title: "IND Den Haag",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DH/slots/?productKey=TKV&persons=1",
      },
      {
        title: "IND Zwolle",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/ZW/slots/?productKey=TKV&persons=1",
      },
      {
        title: "IND Den Bosch",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DB/slots/?productKey=TKV&persons=1",
      },
      {
        title: "Expatcenter Nijmegen",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/0d85a757c13105ba0c26c3d177a7a884/slots/?productKey=TKV&persons=1",
      },
    ],
  },
  residenceendorsementstickerukraine: {
    title: "Residence endorsement sticker Ukraine",
    link: "https://oap.ind.nl/oap/en/#/OEK",
    branches: [
      {
        title: "IND Amsterdam Ukraine",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/18ea477e6f104c26dd35ce43799f7f1d/slots/?productKey=b3f17bbbb3f6391444ee7d4e25d9f03b&persons=1",
      },
    ],
  },
  residenceendorsementsticker: {
    title: "Residence endorsement sticker",
    link: "https://oap.ind.nl/oap/en/#/VAA",
    branches: [
      {
        title: "IND Amsterdam",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/AM/slots/?productKey=VAA&persons=1",
      },
      {
        title: "IND Den Haag",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DH/slots/?productKey=VAA&persons=1",
      },
      {
        title: "IND Zwolle",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/ZW/slots/?productKey=VAA&persons=1",
      },
      {
        title: "IND Den Bosch",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/DB/slots/?productKey=VAA&persons=1",
      },
      {
        title: "Expatcenter Nijmegen",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/0d85a757c13105ba0c26c3d177a7a884/slots/?productKey=VAA&persons=1",
      },
    ],
  },
  familyreunificationasylum: {
    title: "family reunification Asylum",
    link: "https://oap.ind.nl/oap/en/#/nra",
    branches: [
      {
        title: "IND Amsterdam Ukraine",
        url: "https://corsproxy.io/?https://oap.ind.nl/oap/api/desks/f26544ed932047feda02624864e9673b/slots/?productKey=7c1c67ff1534a6de7336515672e4b0d2&persons=1",
      },
    ],
  },
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppointmentTimes />
    </QueryClientProvider>
  );
}

function useAppointments(url) {
  return useQuery(
    "appointments",
    async () => {
      const { data } = await axios.get(url);
      if (data === "") {
        return [];
      }
      const dataRes =
        JSON.parse(
          // `{"status":"OK","data":[{"key":"92267c090a886327b9c21d6ab4d88a3` +
          data.slice(0, 5) === `)]}',` ? data.slice(5) : data
        ).data.slice(0, maxToShow) || [];
      return dataRes;
    },
    { enabled: !!url, refetchInterval: interval }
  );
}

function AppointmentTimes() {
  const [selectedUrl, setSelectedUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isMuted, setIsMuted] = useState(false);

  const { status, data, error, isFetching, refetch } =
    useAppointments(selectedUrl);

  const [play1] = useSound(sound1);
  const [play2] = useSound(sound2);
  const [play3] = useSound(sound3);
  const [play4] = useSound(sound4);
  const sounds = [play1, play2, play3, play4];
  useEffect(() => {
    if (isMuted) {
      return;
    }
    const needsToNotifiy =
      data &&
      data.some((item) => {
        return new Date(item.date) < new Date(selectedDate);
      });

    if (needsToNotifiy) {
      const selectToPlay = sounds[Math.floor(Math.random() * sounds.length)];
      selectToPlay();
    }
  }, [selectedDate, data, isMuted]);

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <img
        src="./beams.jpeg"
        alt=""
        className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        width="1308"
      />
      <div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      <div className="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        {(isFetching || status === "loading") && (
          <span className="animate-ping absolute h-4 w-4 top-5 left-5 rounded-full bg-sky-400 opacity-75"></span>
        )}

        <span
          className="absolute h-4 w-4 top-5 right-7 cursor-pointer"
          onClick={() => setIsMuted(!isMuted)}
        >
          {isMuted ? <OnIcon /> : <OffIcon />}
        </span>

        <div className="mx-auto max-w-md">
          <img src="./ind.png" alt="IND" />

          <div className="divide-y divide-gray-300/50">
            <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
              <p>
                IND Appointment Hunter is an easy way to watch and find free
                time slots from cancelation.
              </p>

              <Formik
                initialValues={{
                  branches: "",
                  lookingFor: "",
                }}
                enableReinitialize={true}
              >
                {(props) => {
                  const {
                    values,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleReset,
                  } = props;
                  return (
                    <form>
                      <label
                        htmlFor="lookingFor"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Looking for an appointment before
                      </label>

                      <input
                        className="bg-gray-100 text-gray-700 w-full px-3 py-2 text-xl rounded-md shadow-inner"
                        id="lookingFor"
                        name="lookingFor"
                        placeholder="Date"
                        type="date"
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedDate(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setSelectedDate(e.target.value);
                        }}
                      />

                      {values.lookingFor && (
                        <>
                          <label
                            htmlFor="selectedProduct"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          >
                            Product
                          </label>
                          <select
                            id="selectedProduct"
                            name="selectedProduct"
                            onChange={(e) => {
                              queryClient
                                .cancelQueries("appointments", { exact: true })
                                .then(() => {
                                  queryClient.removeQueries("appointments", {
                                    exact: true,
                                  });
                                  queryClient.resetQueries("appointments", {
                                    exact: true,
                                  });
                                  refetch();
                                });
                              setSelectedUrl("");
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            className="bg-gray-100 text-gray-700 w-full px-3 py-2 text-xl rounded-md shadow-inner"
                          >
                            {products.map(({ title, lable }, key) => (
                              <option key={key} value={title}>
                                {lable}
                              </option>
                            ))}
                          </select>
                        </>
                      )}

                      {values.selectedProduct && (
                        <>
                          <label
                            htmlFor="selectedUrl"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          >
                            <h4>Branch</h4>
                          </label>
                          <select
                            id="selectedUrl"
                            name="selectedUrl"
                            onChange={(e) => {
                              queryClient
                                .cancelQueries("appointments", { exact: true })
                                .then(() => {
                                  queryClient.removeQueries("appointments", {
                                    exact: true,
                                  });
                                  queryClient.resetQueries("appointments", {
                                    exact: true,
                                  });
                                  refetch();
                                });

                              handleChange(e);
                              setSelectedUrl(e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              setSelectedUrl(e.target.value);
                            }}
                            className="bg-gray-100 text-gray-700 w-full px-3 py-2 text-xl rounded-md shadow-inner"
                          >
                            <option value={""}>Select a Branch</option>

                            {productsData[values.selectedProduct].branches.map(
                              ({ title, url }, key) => (
                                <option
                                  key={key}
                                  value={url}
                                  selected={url === selectedUrl}
                                  defaultValue={url === selectedUrl}
                                >
                                  {title}
                                </option>
                              )
                            )}
                          </select>
                        </>
                      )}

                      {dirty && (
                        <div className="flex items-center justify-between mt-3 mb-3">
                          <button
                            type="button"
                            className=" w-full  rounded-md shadow-inner bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4"
                            onClick={(e) => {
                              handleReset(e);
                              setSelectedUrl("");
                            }}
                            disabled={!dirty}
                          >
                            Reset
                          </button>
                        </div>
                      )}

                      {selectedUrl && (
                        <div>
                          {status === "loading" ? (
                            <div className="rounded-md p-4 max-w-sm w-full mx-auto">
                              <div className="animate-pulse flex space-x-4">
                                <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                                <div className="flex-1 space-y-6 py-1">
                                  <div className="h-2 bg-slate-700 rounded"></div>
                                  <div className="space-y-3">
                                    <div className="grid grid-cols-3 gap-4">
                                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                                    </div>
                                    <div className="h-2 bg-slate-700 rounded"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : status === "error" ? (
                            <span>
                              Error: {error.message}
                              {isFetching ? (
                                <div className="text-center">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                  Background Updating...
                                </div>
                              ) : (
                                " "
                              )}
                            </span>
                          ) : (
                            <>
                              <div>
                                <ul className="space-y-1">
                                  {data &&
                                    data.map((item) => {
                                      const date = item.date;
                                      const isExpected =
                                        new Date(date) <
                                        new Date(values.lookingFor);
                                      return (
                                        <li
                                          className="flex items-center"
                                          key={`${item.date}-${item.startTime}`}
                                        >
                                          <svg
                                            className={`h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2 ${
                                              isExpected
                                                ? "fill-red-100 stroke-red-500"
                                                : ""
                                            }`}
                                            strokeLinejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="11" />
                                            <path
                                              d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                                              fill="none"
                                            />
                                          </svg>
                                          <span
                                            className="ml-4"
                                            style={{
                                              fontWeight: isExpected
                                                ? "bold"
                                                : "normal",
                                              color: isExpected
                                                ? "red"
                                                : "black",
                                            }}
                                          >
                                            {item.date} ({item.startTime}-
                                            {item.endTime})
                                            {isExpected && (
                                              <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-sky-500 hover:text-sky-600 ml-4"
                                                href={
                                                  productsData[
                                                    values.selectedProduct
                                                  ]?.link || "#"
                                                }
                                              >
                                                Open IND &rarr;
                                              </a>
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  {selectedUrl && data && data.length === 0 && (
                                    <li
                                      className="flex items-center bg-orange-100 text-orange-400 text-sm font-bold px-4 py-3"
                                      role="alert"
                                    >
                                      There is no available time yet! Please
                                      wait! The application will try every
                                      couple of seconds to find available times.
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div>
                                {isFetching ? (
                                  <div className="text-center">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                    Background Updating...
                                  </div>
                                ) : (
                                  " "
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </form>
                  );
                }}
              </Formik>

              <div>
                <div
                  className="flex items-center bg-blue-100 text-blue-400 text-sm font-bold px-4 py-3"
                  role="alert"
                >
                  <svg
                    className="fill-current w-10 h-10 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>
                    Please wait for dates highlighted in red and click on the
                    button to open the link and register promptly. If you
                    encounter an error message, please wait until the app
                    requests again.
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-8 text-base font-semibold leading-7">
              <div
                className="flex items-center bg-gray-100 text-gray-400 text-sm font-bold px-4 py-3"
                role="alert"
              >
                The purpose of this tool is solely to assist and streamline the
                process without any financial gain, and it will remain free
                indefinitely.
              </div>
              <div className="text-gray-400 text-center">
                Developed with ♥︎ by Behrouz
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
